@import "./styles/index.scss";
@import "./styles/variablesOverride.scss";
@import "ecom-selfcare-apps-core/build/typography";
@import "~normalize.css/normalize.css";

* {
    box-sizing: border-box;
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: $weightRegular;
    color: $gray-800;

    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

:global(.swal2-container),
.app {
    font-family: $primary-font-family;
}
