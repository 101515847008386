// CSS variables
:root {
    --warning-color: #{$red-700};

    --validation-input-padding: 16px;
    --validation-input-autofill-font-family: Roboto, sans-serif;

    --button-primary-bg-color: #{$red-600};
    --button-primary-text-color: #{$white};
    --button-secondary-bg-color: #{$white};
    --button-secondary-text-color: #{$indigo-900};

    --button-primary-bg-color: #{$red-600};
    --button-primary-hover-bg-color: #{darken($color: $red-600, $amount: 10)};
    --button-secondary-text-color: #{$indigo-900};
    --button-link-text-color: #{$indigo-900};
    --button-link-hover-text-color: #{lighten($color: $indigo-900, $amount: 10)};

    --checkbox-active-background-color: #{$red-600};

    --icon-button-color: #{$indigo-900};

    --footer-background-color: #{$light-green-500};

    --header-profile-color: #{$light-green-500};
    --header-profile-background-color: #{$light-green-50};

    --loader-color: #{$light-green-500};

    --siblings-navigation-active-color: #{$indigo-900};

    --content-header-back-button-color: #{$indigo-900};
}
