@import "../../styles/index.scss";

.content {
    color: $white;
    font-size: 14px;
    text-align: center;
    line-height: 1.5;
}

.privacyPolicy {
    text-decoration: none;
    color: $blue-900;
    white-space: nowrap;
    margin-left: 4px;

    :visited {
        color: $blue-900;
    }
}
